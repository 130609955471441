import React, { Fragment } from "react";
import "./users.css";
import { useState, useEffect, useRef } from "react";
import url from "../../config/axios";
import moment from "moment";
import { useHistory, useLocation } from "react-router-dom";
import { serializeQueryStringsFromObject } from "../../helpers/serializeToQueryStrings";
import { CircularProgress } from "@mui/material";
// import debounce from "../../helpers/debounce";
import InvokeLayoutComponent from "../../components/common/InvokeLogout";
import Popup from "reactjs-popup";
import { memo } from "react";

const UserTable = ({ users }) => {
  const history = useHistory();
  const color = ["#03b7a5", "#fc3986", "#ab48d5", "#2bb72b", "#bdd72a"];
  let num = 0;

  function assignColor() {
    var random = Math.floor(Math.random() * color.length);
    while (num === random) {
      random = Math.floor(Math.random() * color.length);
    }
    num = random;
    var value = color[random];
    // console.log(value, "VALUES");
    return value;
  }
  return (
    <tbody>
      {users.map((user, i) => {
        return (
          <tr key={i}>
            <td colSpan="6">
              <div
                style={{
                  display: "flex",
                }}>
                {user.image ? (
                  <img
                    alt="yo1"
                    src={user.image}
                    className="img-fluid "
                    style={{
                      width: "40px",
                      height: "40px",
                      borderRadius: "5px",
                    }}
                  />
                ) : (
                  <div
                    className="image-thumbnail-div"
                    style={{
                      backgroundColor: assignColor(),
                      width: "40px",
                      height: "40px",
                      borderRadius: "5px",
                    }}>
                    <span>{user?.name?.substring(0, 1)}</span>
                  </div>
                )}

                <span style={{ paddingTop: "10px" }}>{user.name}</span>
              </div>
            </td>
            <td className="adminName">
              {user.email ? user.email : user.mobileNo} <br />
            </td>
            <td className="adminName">
              {moment(user.accountCreatedOn).format("MMMM Do YYYY, h:mm A")}
            </td>
            <td className="adminName">
              {moment(user.updatedAt).format("MMMM Do YYYY, h:mm A")}
            </td>
            <td className="status">{user.isActive ? "In Active" : "Active"}</td>
            <td>
              {/* <i
                            className="fas fa-pen"
                            onClick={() => {
                              history.push('/user-detail')
                            }}
                          ></i> */}
              <button
                style={{
                  backgroundColor: "white",
                  color: "#5D5FEF",
                }}
                className="btn btn-primary btn-primary-hover"
                onClick={() => {
                  console.log(user, "user selected");
                  localStorage.setItem("singleUser", JSON.stringify(user));
                  history.push("/user-detail");
                }}>
                <i className="fas fa-eye"></i>View Details
              </button>
            </td>
          </tr>
        );
      })}
    </tbody>
  );
};

const MemoizedTable = memo(UserTable);
export default function UsersIndexV2() {
  const history = useHistory();
  const [users, setUsers] = useState([]);
  const [invokeLogOut, setInvokeLogout] = useState(false);
  const initialPaginationData = {
    page: 1,
    limit: 30,
    latestFirst: 1,
    search: "",
  };
  const [queryData, setQueryData] = useState({
    ...initialPaginationData,
  });
  const notFoundRef = useRef();
  const [searchTerm, setSearchTerm] = useState("");
  const performSearchOnEnter = () => {
    handleChange("search", searchTerm, true);
  };
  // const [previousSearchTerm, setPreviousSearchTerm] = useState("");

  // const performSearch = debounce(() => {
  //   if ((searchTerm + "").trim() !== (previousSearchTerm + "").trim()) {
  //     setPreviousSearchTerm(searchTerm);
  //     handleChange("search", searchTerm, true);
  //   } else if (searchTerm === "") {
  //     setPreviousSearchTerm("");
  //     handleChange("search", "", true);
  //   }
  // }, 1500);
  // useEffect(
  //   () => {
  //     if (parsed && !loading) {
  //       performSearch();
  //     }
  //   },
  //   //eslint-disable-next-line
  //   [searchTerm]
  // );
  //parse passed queries from the URL first
  const { search } = useLocation();
  const [parsed, setParsed] = useState(false);
  useEffect(
    () => {
      const query = new URLSearchParams(search);

      let page =
        Number.isNaN(query.get("page")) || !query.get("page")
          ? 1
          : +query.get("page");
      let limit =
        Number.isNaN(query.get("limit")) || !query.get("limit")
          ? 30
          : +query.get("limit");
      let latestFirst =
        Number.isNaN(query.get("latestFirst")) || !query.get("latestFirst")
          ? 1
          : +query.get("latestFirst");
      let searchParams = query.get("search");
      let queryParams = {
        ...queryData,
        page,
        limit,
        latestFirst,
        search: searchParams,
      };
      setSearchTerm(searchParams);
      setQueryData({ ...queryParams });
      setParsed(true);
    },
    //eslint-disable-next-line
    []
  );

  const [paginationData, setPaginationData] = useState({
    currentPage: 1,
    perPage: 30,
    totalPages: 0,
    total: 0,
  });
  const [loading, setLoading] = useState(true);

  //call to fetch data after parsing
  useEffect(
    () => {
      if (parsed) {
        GetAllUsers({ ...queryData });
      }
    },
    //eslint-disable-next-line
    [parsed, queryData]
  );

  /**
   *
   * @param {string} propName property to change
   * @param {*} propValue corresponding value of that property
   * @param {*} flush changes every other values to initial data
   */
  const handleChange = (propName, propValue, flush = false) => {
    let query = {};
    if (flush) {
      query = {
        ...initialPaginationData,
        [propName]: propValue,
      };
    } else {
      query = { ...queryData, [propName]: propValue };
    }
    const queryString = serializeQueryStringsFromObject(query);
    history.push(`/users${queryString}`);
    setQueryData({ ...query });
  };

  const GetAllUsers = () => {
    setLoading(true);
    let queryParams = { ...queryData };
    //sanity
    Object.keys(queryParams).forEach((key) => {
      if (!queryParams[key]) {
        delete queryParams[key];
      }
    });
    url
      .get(`/v2/users/paginated`, {
        params: {
          ...queryParams,
        },
      })
      .then(async (res) => {
        // console.log('res', res)
        if (res.data.Message === "Success") {
          if (!res.data?.data?.users?.length) {
            notFoundRef.current.open();
          }
          setUsers(res.data?.data?.users);
          console.log(res?.data?.data?.paginationData);
          setPaginationData({
            ...paginationData,
            ...res?.data?.data?.paginationData,
          });
        } else {
          console.error(res);
        }
        setLoading(false);
      })
      .catch((e) => {
        if (+e?.response?.status === 401) {
          setInvokeLogout(true);
        }
        setLoading(false);
        console.error(e?.response);
      });
  };

  return (
    <div className="row">
      <div className="col-md-12">
        <div className="card">
          {loading ? (
            <div
              className="row"
              style={{
                padding: "1em",
                alignItems: "center",
                justifyContent: "center",
              }}>
              <CircularProgress />
            </div>
          ) : (
            <Fragment>
              <div className="row">
                <div className="col-md-12">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="input-group">
                        <input
                          type="text"
                          onChange={(e) => {
                            setSearchTerm(e.target.value);
                          }}
                          onKeyPress={(e) => {
                            if (e.key === "Enter") {
                              performSearchOnEnter();
                            }
                          }}
                          value={searchTerm}
                          className="form-control table-search"
                          placeholder="Type name or email and press Enter"
                          aria-label="Input group example"
                          aria-describedby="btnGroupAddon"
                        />
                        <div className="input-group-prepend">
                          <div
                            className="input-group-text table-search-icon"
                            id="btnGroupAddon"
                            style={{
                              cursor: "pointer",
                            }}
                            onClick={() => performSearchOnEnter()}>
                            <i className="fas fa-search"></i>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-2 filters-bar">
                      <div className="dropdown">
                        {/* <a
                      className="btn cannaby-light"
                      href="/"
                      role="button"
                      id="dropdownMenuLink"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      <i className="fas fa-filter"></i>
                    </a> */}

                        <div
                          className="dropdown-menu"
                          aria-labelledby="dropdownMenuLink">
                          <a className="dropdown-item" href="/">
                            Action
                          </a>
                          <a className="dropdown-item" href="/">
                            Another action
                          </a>
                          <a className="dropdown-item" href="/">
                            Something else here
                          </a>
                        </div>
                      </div>
                      <div className="dropdown">
                        {/* <a
                      className="btn cannaby-light"
                      href="/"
                      role="button"
                      id="dropdownMenuLink"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      <i className="fas fa-filter"></i>
                    </a> */}

                        <div
                          className="dropdown-menu"
                          aria-labelledby="dropdownMenuLink">
                          <a className="dropdown-item" href="/">
                            Action
                          </a>
                          <a className="dropdown-item" href="/">
                            Another action
                          </a>
                          <a className="dropdown-item" href="/">
                            Something else here
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4 pagination">
                      {paginationData.totalPages !== 0 &&
                        paginationData.currentPage <=
                          paginationData.totalPages && (
                          <Fragment>
                            <span>
                              {`${
                                paginationData.perPage *
                                  (paginationData.currentPage - 1) +
                                1
                              } - ${
                                paginationData.perPage *
                                  (paginationData.currentPage - 1) +
                                users.length
                              } of ${paginationData.total ?? 0}`}
                            </span>
                            <div
                              className={`btn ${
                                paginationData.currentPage <= 1
                                  ? ""
                                  : "cannaby-light"
                              }`}
                              onClick={() => {
                                if (!(paginationData.currentPage <= 1)) {
                                  handleChange(
                                    "page",
                                    paginationData.currentPage - 1
                                  );
                                }
                              }}
                              role="button">
                              <i className="fas fa-chevron-left"></i>
                            </div>
                            <div
                              onClick={() => {
                                if (
                                  !(
                                    paginationData.currentPage >=
                                    paginationData.totalPages
                                  )
                                ) {
                                  handleChange(
                                    "page",
                                    paginationData.currentPage + 1
                                  );
                                }
                              }}
                              className={`btn ${
                                paginationData.currentPage >=
                                paginationData.totalPages
                                  ? ""
                                  : "cannaby-light"
                              }`}
                              role="button">
                              <i className="fas fa-chevron-right"></i>
                            </div>
                          </Fragment>
                        )}
                    </div>
                  </div>
                </div>
                <div className="col-md-12">
                  {users.length === 0 ? null : ( // </div> //   No data found //   className="row"> //   }} //     padding: "1em", //     justifyContent: "center", //     alignItems: "center", //   style={{ // <div
                    <table className="table table-striped-cust">
                      <thead>
                        <tr>
                          <th scope="col">Name</th>
                          <th scope="col"></th>
                          <th scope="col"></th>
                          <th scope="col"></th>
                          <th scope="col"></th>
                          <th scope="col"></th>
                          <th scope="col">Email</th>
                          <th scope="col">Account Created On</th>
                          <th scope="col">Last Activity</th>
                          <th scope="col">Status</th>
                          <th scope="col"></th>
                        </tr>
                      </thead>
                      <MemoizedTable users={users} />
                    </table>
                  )}
                </div>
              </div>
            </Fragment>
          )}
        </div>
      </div>
      {invokeLogOut && <InvokeLayoutComponent />}
      <Popup
        ref={notFoundRef}
        position="center"
        onClose={() => {
          notFoundRef.current.close();
          setSearchTerm("");
        }}>
        <div style={{ textAlign: "center" }}>
          <i
            style={{
              color: "red",
              fontSize: "70px",
            }}
            // style={{
            //   color: "green",
            //   fontSize: "60px",
            //   border: "10px solid green",
            //   borderRadius: "50px",
            //   padding: "10px",
            // }}
            class="fa fa-ban"></i>
          <h2 className="popup-heading" style={{ marginTop: "20px" }}>
            Failed
          </h2>
          <p>User(s) not found</p>
          <button
            className="btn btn-cannaby"
            style={{ marginTop: "0" }}
            onClick={() => {
              notFoundRef.current.close();
              if (searchTerm?.trim().length !== 0) {
                handleChange("search", "", true);
              }
            }}>
            OK
          </button>
        </div>
      </Popup>
    </div>
  );
}
