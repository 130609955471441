/* eslint-disable no-unused-vars */
// import AddBrand from './AddBrand';
import "./Brands.css";
// import brandImage from '../../images/brandImage.png'
import { useEffect, useState, useCallback, useRef, Fragment } from "react";
import url from "../../config/axios";
import storage from "../../config/firebase";
import moment from "moment";
import Cropper from "react-easy-crop";
import Slider from "@mui/material/Slider";
import getCroppedImg from "../../helpers/cropImage";
import Dialog from "@mui/material/Dialog";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
// import XLSX from "xlsx";
import Popup from "reactjs-popup";
import blank from "../../assets/defaultImage.jpg";
import debounce from "../../helpers/debounce";
import { serializeQueryStringsFromObject } from "../../helpers/serializeToQueryStrings";
import { useHistory, useLocation } from "react-router-dom";
import InvokeLayoutComponent from "../common/InvokeLogout";

const Brands = () => {
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [showCrop, setShowCrop] = useState(false);
  const [open, setOpen] = useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const [showComponent, setComponent] = useState(false);
  const [editBoolean, setEditBoolean] = useState(false);
  const [brands, setBrands] = useState([]);
  const [name, setName] = useState("");
  const [slug, setSlug] = useState("");
  const [brandID, setBrandID] = useState("");
  const [file, setFile] = useState("");
  const [image, setImage] = useState("");
  const notFoundRef = useRef();
  const history = useHistory();
  const color = ["#03b7a5", "#fc3986", "#ab48d5", "#2bb72b", "#bdd72a"];
  //   const [createdDAte, setCreatedDateE] = useState("");
  //   const [convertedJson, setConvertedJson] = useState([]);

  //   const ExcelFileUpload = (event) => {
  //     // console.log(event)
  //     let selected = event.target.files[0];
  //     let fileReader = new FileReader();
  //     fileReader.readAsBinaryString(selected);
  //     fileReader.onload = (event) => {
  //       console.log(event);
  //       let binaryData = event.target.result;
  //       let workbook = XLSX.read(binaryData, { type: "binary" });
  //       console.log(workbook);
  //       workbook.SheetNames.forEach((sheet) => {
  //         const data = XLSX.utils.sheet_to_json(workbook.Sheets[sheet]);
  //         console.log(data);
  //         setConvertedJson(data);
  //       });
  //     };
  //   };

  //   const uploadMultipleData = async () => {
  //     const body = {
  //       json: convertedJson,
  //     };
  //     await url
  //       .post("/brand/multiple", body)
  //       .then(async (res) => {
  //         if (res.data.Message === "Success") {
  //           alert("File Uploaded Successfully");
  //         } else {
  //           alert("Error: Brand already exitst");
  //         }
  //       })
  //       .catch((e) => console.log(e));
  //     setConvertedJson([]);
  //     await getPaginatedBrands();
  //     await getAllBrands();
  //   };

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const showCroppedImage = useCallback(
    async (e) => {
      e.preventDefault();
      try {
        const croppedImage = await getCroppedImg(image, croppedAreaPixels, 0);
        console.log("donee", { croppedImage });
        localStorage.setItem("ImageSet", croppedImage);
        setImage(croppedImage);
        setShowCrop(false);
        setOpen(false);
      } catch (e) {
        console.error(e);
      }
    },
    [croppedAreaPixels, image]
  );

  const updateBrand = async (blob) => {
    await url
      .put(`/brand/?brandID=${brandID}`, {
        name: name,
        image: blob,
        slug: slug,
      })
      .then((res) => {
        // console.log("Updated data is: ", res.data);
        if (res.data.Message === "Success") {
          setName("");
          setImage("");
          setSlug("");
          setBrandID("");
          setSlug("");
          setComponent(false);
          setEditBoolean(false);
          setBrands([
            ...brands.map((obj) =>
              obj?.brandID === res?.data?.data?.brandID ? res?.data?.data : obj
            ),
          ]);
        } else {
          alert("Error: Something went wrong");
        }
        // console.log(res);
        setComponent(false);
        setEditBoolean(false);
      })
      .catch((e) => console.error(e));
  };

  const editBrand = async (brand) => {
    // await localStorage.setItem('brand', JSON.stringify(brand));
    setName(brand.name);
    setImage(brand.image);
    setSlug(brand.slug);
    setBrandID(brand.brandID);
    // console.log('brand', brand);
    await setEditBoolean(true);
    await setComponent(!showComponent);

    // setCreatedDateE(brand.createdAt);
  };

  const imageSelector = (event) => {
    if (event.target.files[0]) {
      setImage(URL.createObjectURL(event.target.files[0]));
      setFile(event.target.files[0]);
      setOpen(true);
      setShowCrop(true);
    }
  };

  //   const incrementPage = () => {
  //     if (brands.length < 30) {
  //     } else {
  //       setPage(page + 1);
  //     }
  //   };

  //   const decrementPage = () => {
  //     if (page > 1) {
  //       setPage(page - 1);
  //     }
  //   };

  const fileUpdate = () => {
    // const storageRef = ref(storage, `images/${file.name}`);
    if (file !== "") {
      const uploadTask = storage
        .ref(`images/${file.name}`)
        .putString(localStorage.getItem("ImageSet"), "data_url");
      uploadTask.on(
        "state_changed",
        (snapshot) => {
          // const progress = Math.round(
          //   (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          // );
        },
        (error) => {
          console.log(error);
        },
        () => {
          storage
            .ref("images")
            .child(file.name)
            .getDownloadURL()
            .then(async (blob) => {
              console.log(blob);
              await updateBrand(blob);
            });
        }
      );
    } else {
      updateBrand(image);
    }
  };

  //   const fileUpload = () => {
  //     // const storageRef = ref(storage, `images/${file.name}`);
  //     if (file !== "") {
  //       const uploadTask = storage
  //         .ref(`images/${file.name}`)
  //         .putString(localStorage.getItem("ImageSet"), "data_url");

  //       uploadTask.on(
  //         "state_changed",
  //         (snapshot) => {
  //           // const progress = Math.round(
  //           //   (snapshot.bytesTransferred / snapshot.totalBytes) * 100
  //           // );
  //         },
  //         (error) => {
  //           console.log(error);
  //         },
  //         () => {
  //           storage
  //             .ref("images")
  //             .child(file.name)
  //             .getDownloadURL()
  //             .then(async (blob) => {
  //               console.log(blob);
  //               await addBrand(blob);
  //             });
  //         }
  //       );
  //     } else {
  //       addBrand("");
  //     }
  //   };

  const closeComp = () => {
    setComponent(false);
  };

  const [invokeLogOut, setInvokeLogout] = useState(false);
  const initialPaginationData = {
    page: 1,
    limit: 30,
    latestFirst: -1,
    search: "",
  };
  const [queryData, setQueryData] = useState({
    ...initialPaginationData,
  });
  const [searchTerm, setSearchTerm] = useState("");
  const [previousSearchTerm, setPreviousSearchTerm] = useState("");
  //whole editable object will be place here
  const [currentEditingIndex, setCurrentEditingIndex] = useState(null);
  const performSearch = debounce(() => {
    if ((searchTerm + "").trim() !== (previousSearchTerm + "").trim()) {
      setPreviousSearchTerm(searchTerm);
      handleChange("search", searchTerm, true);
    } else if (searchTerm === "") {
      setPreviousSearchTerm("");
      handleChange("search", "", true);
    }
  }, 1500);
  useEffect(
    () => {
      if (parsed && !loading) {
        performSearch();
      }
    },
    //eslint-disable-next-line
    [searchTerm]
  );
  //parse passed queries from the URL first
  const { search } = useLocation();
  const [parsed, setParsed] = useState(false);
  useEffect(
    () => {
      const query = new URLSearchParams(search);

      let page =
        Number.isNaN(query.get("page")) || !query.get("page")
          ? 1
          : +query.get("page");
      let limit =
        Number.isNaN(query.get("limit")) || !query.get("limit")
          ? 30
          : +query.get("limit");
      let latestFirst =
        Number.isNaN(query.get("latestFirst")) || !query.get("latestFirst")
          ? -1
          : +query.get("latestFirst");
      let searchParams = query.get("search");
      let queryParams = {
        ...queryData,
        page,
        limit,
        latestFirst,
        search: searchParams,
      };
      setSearchTerm(searchParams);
      setQueryData({ ...queryParams });
      setParsed(true);
    },
    //eslint-disable-next-line
    []
  );

  const [paginationData, setPaginationData] = useState({
    currentPage: 1,
    perPage: 30,
    totalPages: 0,
    total: 0,
  });
  const [loading, setLoading] = useState(true);
  //call to fetch data after parsing
  useEffect(
    () => {
      if (parsed) {
        GetAllBrands({ ...queryData });
      }
    },
    //eslint-disable-next-line
    [parsed, queryData]
  );

  /**
   *
   * @param {string} propName property to change
   * @param {*} propValue corresponding value of that property
   * @param {*} flush changes every other values to initial data
   */
  const handleChange = (propName, propValue, flush = false) => {
    if (currentEditingIndex === null) {
      let query = {};
      if (flush) {
        query = {
          ...initialPaginationData,
          [propName]: propValue,
        };
      } else {
        query = { ...queryData, [propName]: propValue };
      }
      const queryString = serializeQueryStringsFromObject(query);
      history.push(`/brands${queryString}`);
      setQueryData({ ...query });
    }
  };

  const GetAllBrands = () => {
    setLoading(true);
    let queryParams = { ...queryData };
    //sanity
    Object.keys(queryParams).forEach((key) => {
      if (!queryParams[key]) {
        delete queryParams[key];
      }
    });
    url
      .get(`/v2/admin/brands/paginated`, {
        params: {
          ...queryParams,
        },
      })
      .then(async (res) => {
        // console.log('res', res)
        if (res.data.Message === "Success") {
          if (!res.data?.data?.brands?.length) {
            notFoundRef.current.open();
          }
          console.log("Brands are: ", res.data?.data?.brands);
          setBrands(res.data?.data?.brands);
          setPaginationData({
            ...paginationData,
            ...res?.data?.data?.paginationData,
          });
        } else {
          console.error(res);
        }
        setLoading(false);
      })
      .catch((e) => {
        if (+e?.response?.status === 401) {
          setInvokeLogout(true);
        }
        setLoading(false);
        console.error(e?.response);
      });
  };

  let num = 0;

  function assignColor() {
    var random = Math.floor(Math.random() * color.length);
    while (num === random) {
      random = Math.floor(Math.random() * color.length);
    }
    num = random;
    var value = color[random];
    console.log(value, "VALUES");
    return value;
  }

  return (
    <div className="row">
      <div className="col-md-8">
        <div className="card card-cat">
          {/* <button className="btn btn-cannaby btn-cat" onClick={() => setAddBrandComponent()}>
            <i className="fas fa-plus-circle"></i>
            Add Manufacturer
          </button> */}
          <div className="row">
            <div className="col-md-12">
              <div className="row">
                <div className="col-md-6">
                  <div className="input-group">
                    <input
                      type="text"
                      className="form-control table-search"
                      value={searchTerm}
                      onChange={(e) => setSearchTerm(e.target.value)}
                      placeholder="Search by name"
                      aria-label="Input group example"
                      aria-describedby="btnGroupAddon"
                    />
                    <div className="input-group-prepend">
                      <div
                        className="input-group-text table-search-icon"
                        id="btnGroupAddon">
                        <i className="fas fa-search"></i>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-2 filters-bar">
                  <div className="dropdown">
                    {/* <a className="btn cannaby-light" href="/" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                      <i className="fas fa-filter"></i>
                    </a> */}

                    <div
                      className="dropdown-menu"
                      aria-labelledby="dropdownMenuLink">
                      <a className="dropdown-item" href="/">
                        Action
                      </a>
                      <a className="dropdown-item" href="/">
                        Another action
                      </a>
                      <a className="dropdown-item" href="/">
                        Something else here
                      </a>
                    </div>
                  </div>
                  <div className="dropdown">
                    {/* <a className="btn cannaby-light" href="/" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                      <i className="fas fa-filter"></i>
                    </a> */}

                    <div
                      className="dropdown-menu"
                      aria-labelledby="dropdownMenuLink">
                      <a className="dropdown-item" href="/">
                        Action
                      </a>
                      <a className="dropdown-item" href="/">
                        Another action
                      </a>
                      <a className="dropdown-item" href="/">
                        Something else here
                      </a>
                    </div>
                  </div>
                </div>
                <div className="col-md-4 pagination">
                  {paginationData.totalPages !== 0 &&
                    paginationData.currentPage <= paginationData.totalPages && (
                      <Fragment>
                        <span>
                          {`${
                            paginationData.perPage *
                              (paginationData.currentPage - 1) +
                            1
                          } - ${
                            paginationData.perPage *
                              (paginationData.currentPage - 1) +
                            brands.length
                          } of ${paginationData.total ?? 0}`}
                        </span>
                        <div
                          className={`btn ${
                            paginationData.currentPage <= 1
                              ? ""
                              : "cannaby-light"
                          }`}
                          onClick={() => {
                            if (!(paginationData.currentPage <= 1)) {
                              handleChange(
                                "page",
                                paginationData.currentPage - 1
                              );
                            }
                          }}
                          role="button">
                          <i className="fas fa-chevron-left"></i>
                        </div>
                        <div
                          onClick={() => {
                            if (
                              !(
                                paginationData.currentPage >=
                                paginationData.totalPages
                              )
                            ) {
                              handleChange(
                                "page",
                                paginationData.currentPage + 1
                              );
                            }
                          }}
                          className={`btn ${
                            paginationData.currentPage >=
                            paginationData.totalPages
                              ? ""
                              : "cannaby-light"
                          }`}
                          role="button">
                          <i className="fas fa-chevron-right"></i>
                        </div>
                      </Fragment>
                    )}
                </div>
              </div>
            </div>
            <div className="col-md-12">
              <table className="table table-striped-cust">
                <thead>
                  <tr>
                    <th scope="col">Name</th>
                    <th scope="col"></th>
                    <th scope="col"></th>
                    <th scope="col"></th>

                    <th scope="col"></th>
                    <th scope="col"></th>
                    <th scope="col">Products</th>
                    <th scope="col">Last Modified</th>
                    <th scope="col" className="hidden">
                      Status
                    </th>
                    <th scope="col"></th>
                  </tr>
                </thead>
                <tbody>
                  {brands.map((brand, i) => {
                    return (
                      <tr key={i}>
                        <td colSpan="6">
                          {brand.image ? (
                            <div>
                              <img
                                alt="yo1"
                                src={brand.image}
                                className="img-fluid "
                                width="40"
                              />
                              {brand.name}
                            </div>
                          ) : (
                            <div style={{ display: "flex" }}>
                              <div
                                className="image-thumbnail-div"
                                style={{
                                  backgroundColor: assignColor(),
                                  width: "40px",
                                  height: "40px",
                                }}>
                                <span>{brand?.name?.substring(0, 1)}</span>
                              </div>
                              <span style={{ paddingTop: "10px" }}>
                                {brand.name}
                              </span>
                            </div>
                          )}
                        </td>
                        {/* <td colSpan="6">
                          <img
                            alt="yo1"
                            src={brand.image ? brand.image : blank}
                            className="img-fluid "
                            width="40"
                          />
                          {brand.name}
                        </td> */}
                        <td>
                          {brand.countInventory?.length
                            ? brand.countInventory[0].count
                            : 0}
                        </td>
                        <td>
                          {moment(brand.updatedAt).format(
                            "MMMM Do YYYY h:mm A"
                          )}
                        </td>
                        <td className="status hidden">
                          {brand.status ? (
                            <span>Published</span>
                          ) : (
                            <span>Not Published</span>
                          )}
                        </td>
                        <td>
                          <i
                            onClick={() => editBrand(brand)}
                            className="fas fa-pen"></i>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>

                {/* <tr>
                          <td colspan="6">
                            <img alt="yo5" src={brandImage} 
                              className="img-fluid " width="40" />
                            Mark
                          </td>
                          <td>500</td>
                          <td>Sep 21, 2021 2:30 PM</td>
                          <td className="status">Published</td>
                          <td><i onClick={() => editBrand()} className="fas fa-pen"></i></td>
                        </tr>
                        <tr>
                          <td colspan="6">
                            <img alt="yo2" src={brandImage}
                              className="img-fluid " width="40" />
                            Mark
                          </td>
                          <td>500</td>
                          <td>Sep 21, 2021 2:30 PM</td>
                          <td className="status">Published</td>
                          <td><i className="fas fa-pen"></i></td>
                        </tr>
                        <tr>
                          <td colspan="6">
                            <img alt="yo3" src={brandImage}
                              className="img-fluid " width="40" />
                            Mark
                          </td>
                          <td>500</td>
                          <td>Sep 21, 2021 2:30 PM</td>
                          <td className="status">Published</td>
                          <td><i className="fas fa-pen"></i></td>
                        </tr>
                        
                        <tr>
                          <td colspan="6">
                            <img alt="yo4" src={brandImage}
                              className="img-fluid " width="40" />
                            Mark
                          </td>
                          <td>500</td>
                          <td>Sep 21, 2021 2:30 PM</td>
                          <td className="status">Published</td>
                          <td><i className="fas fa-pen"></i></td>
                        </tr> */}
              </table>
            </div>
          </div>
        </div>
      </div>
      <div className="col-md-4">
        {/* {showComponent && <AddBrand 
                  editBoolean={editBoolean} 
                  setEditBoolean={setEditBoolean} 
                  setComponent={setComponent} 
                  brands={brands}
                  setBrands={setBrands}
                /> } */}
        {showComponent && (
          <div className="card">
            <div>
              <div className="headerEA">
                <h4>
                  {editBoolean ? "Edit Manufacturer" : "Add Manufacturer"}
                </h4>
                <button className="btn close-btn" onClick={closeComp}>
                  <i class="fas fa-times"></i>
                </button>
              </div>

              <br></br>
              <form onSubmit={(e) => e.preventDefault()}>
                <div className="group-comb">
                  <div className="form-group" style={{ marginBottom: "20px" }}>
                    <label>Manufacturer Name</label>
                    <input
                      // disabled
                      type="text"
                      value={name}
                      disabled={true}
                      onChange={(e) => setName(e.target.value)}
                      className="form-control"
                      id="exampleFormControlInput1"
                      placeholder="name"
                      // disabled
                    />
                  </div>
                  {/* <div className="form-group">
                    <label>Slug</label>
                    <input
                      type="text"
                      value={slug}
                      onChange={(e) => setSlug(e.target.value)}
                      className="form-control"
                      id="exampleFormControlInput1"
                      placeholder="slug"
                    />
                  </div> */}
                </div>

                <div className="group-comb">
                  <div className="form-group img-drop">
                    <label className="custom-file-upload">
                      <input
                        type="file"
                        id="image"
                        name="Image"
                        onChange={imageSelector}
                        accept="image/png, image/jpeg"
                      />
                      Browse
                    </label>
                    <img
                      alt=""
                      width="150"
                      height="120"
                      src={image}
                      className="rounded mx-auto d-block galimage"></img>
                    {showCrop && (
                      <Dialog
                        fullScreen={fullScreen}
                        open={open}
                        fullWidth={true}
                        onClose={() => setOpen(false)}
                        aria-labelledby="responsive-dialog-title">
                        <div
                          className="App"
                          style={{
                            padding: "1em",
                            width: "auto",
                            height: "auto",
                          }}>
                          <div className="crop-container">
                            <Cropper
                              image={image}
                              crop={crop}
                              zoom={zoom}
                              aspect={4 / 3}
                              onCropChange={setCrop}
                              onCropComplete={onCropComplete}
                              onZoomChange={setZoom}
                            />
                          </div>
                          <div className="controls">
                            <Slider
                              value={zoom}
                              min={1}
                              max={3}
                              step={0.1}
                              aria-labelledby="Zoom"
                              onChange={(e, zoom) => setZoom(zoom)}
                              // classes={{ root: "slider" }}
                            />
                          </div>
                          <div style={{ textAlign: "center" }}>
                            <button
                              className="btn btn-primary btn-md"
                              style={{ padding: "5px 20px" }}
                              onClick={(e) => showCroppedImage(e)}>
                              Crop
                            </button>
                          </div>
                        </div>
                      </Dialog>
                    )}
                  </div>
                </div>

                <div className="group">
                  {editBoolean && (
                    <button
                      type="button"
                      onClick={fileUpdate}
                      class="btn btn-primary m-2">
                      Update
                    </button>
                  )}
                  {/* {editBoolean && <button type="button" onClick={deleteBrand} className="btn btn-danger m-2"><span><i className="fa fa-trash-alt"></i></span></button>} */}
                  {/* {!editBoolean && (
                    <button
                      type="button"
                      onClick={fileUpload}
                      class="btn btn-primary m-2">
                      Publish
                    </button>
                  )} */}
                </div>
                {editBoolean ? (
                  <p className="createdOn">
                    {/* Created on{" "}
                    {moment(createdDAte).format(" MMM. Do YYYY, h:mm A")}{" "} */}
                  </p>
                ) : (
                  ""
                )}

                {/* {!editBoolean && (
                  <div>
                    <form class="md-form">
                      <div className="file-field">
                        <div className="btn btn-primary btn-sm float-left">
                          <label>
                            <input
                              type="file"
                              id="excel"
                              name="excel"
                              onChange={ExcelFileUpload}
                              accept=".xls, .xlsx"
                            />
                            <span className="mr-2">Select Excel File</span>
                            <i className="fas fa-pen"></i>
                          </label>
                        </div>
                        {convertedJson.length !== 0 && (
                          <div
                            className="btn btn-primary"
                            onClick={uploadMultipleData}>
                            Upload Data
                          </div>
                        )}
                      </div>
                    </form>
                  </div>
                )} */}
              </form>
            </div>
          </div>
        )}
      </div>
      {invokeLogOut && <InvokeLayoutComponent />}
      <Popup
        ref={notFoundRef}
        position="center"
        onClose={() => {
          notFoundRef.current.close();
          setSearchTerm("");
        }}>
        <div style={{ textAlign: "center" }}>
          <i
            style={{
              color: "red",
              fontSize: "70px",
            }}
            // style={{
            //   color: "green",
            //   fontSize: "60px",
            //   border: "10px solid green",
            //   borderRadius: "50px",
            //   padding: "10px",
            // }}
            class="fa fa-ban"></i>
          <h2 className="popup-heading" style={{ marginTop: "20px" }}>
            Failed
          </h2>
          <p>Brand(s) not found</p>
          <button
            className="btn btn-cannaby"
            style={{ marginTop: "0" }}
            onClick={() => {
              notFoundRef.current.close();
              setSearchTerm("");
            }}>
            OK
          </button>
        </div>
      </Popup>
    </div>
  );
};

export default Brands;
